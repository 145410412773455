import React, { useEffect } from "react";
import window from "global/window";

function Support() {
  useEffect(() => {
    window.location.href = "https://classdojo.zendesk.com/hc/en-us";
  });
  return <></>;
}

export default Support;
